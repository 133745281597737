import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "transform-content"
    }}>{`Transform Content`}</h1>
    <p>{`With MDX you can interact with or manipulate raw MDX content.  Since MDX is a part of the `}<a parentName="p" {...{
        "href": "https://unified.js.org"
      }}>{`unified`}</a>{` ecosystem, there are many utilities you can use to work with MDX.`}</p>
    <p>{`You can utilize `}<a parentName="p" {...{
        "href": "https://github.com/vfile/to-vfile"
      }}>{`to-vfile`}</a>{` to read and write MDX files and you can leverage `}<a parentName="p" {...{
        "href": "https://github.com/remarkjs/remark"
      }}>{`remark`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/mdx-js/mdx/tree/master/packages/remark-mdx"
      }}>{`remark-mdx`}</a>{` to parse and process MDX content.  The remark-mdx library is a parsing extension to enhance the Markdown `}<a parentName="p" {...{
        "href": "/advanced/ast"
      }}>{`AST`}</a>{` to understand MDX (resulting in `}<a parentName="p" {...{
        "href": "/advanced/ast#mdxast"
      }}>{`MDXAST`}</a>{`), giving you access and insight to MDX attributes, namely imports, exports, and jsx.`}</p>
    <p>{`Let’s see an example of what we have explained so far.`}</p>
    <p>{`Say we have the following file, `}<inlineCode parentName="p">{`example.mdx`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`import Component from 'Component';

export const meta = {
    prop: 'value'
};

# Title

Content.

<Component />
`}</code></pre>
    <p>{`And our script, `}<inlineCode parentName="p">{`example.js`}</inlineCode>{`, looks as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const {read, write} = require('to-vfile')
const remark = require('remark');
const mdx = require('remark-mdx');

(async () => {
    const path = './example.mdx';
    const file = await read(path);
    const contents = await remark()
        .use(mdx)
        .use(() => (tree) => {
            console.log(tree)
        })
        .process(file);

    await write({
        path,
        contents
    })
})();
`}</code></pre>
    <p>{`Now, running `}<inlineCode parentName="p">{`node example.js`}</inlineCode>{` yields:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  type: "root",
  children: [
    {
      type: "import",
      value: "import Component from 'Component';",
      position: [Object]
    },
    {
      type: "export",
      value: "export const meta = {\\n    prop: 'value'\\n};",
      position: [Object]
    },
    { type: "heading", depth: 1, children: [Array], position: [Object] },
    { type: "paragraph", children: [Array], position: [Object] },
    { type: "jsx", value: "<Component />", position: [Object] }
  ],
  position: {
    start: { line: 1, column: 1, offset: 0 },
    end: { line: 12, column: 1, offset: 113 }
  }
}
`}</code></pre>
    <p>{`From here, you can continue to `}<a parentName="p" {...{
        "href": "/guides/writing-a-plugin"
      }}>{`write your own plugin`}</a>{` or use any of the `}<a parentName="p" {...{
        "href": "/advanced/plugins"
      }}>{`remark plugins`}</a>{` parse or manipulate MDX content.`}</p>
    <p>{`This technique allows you to use things like `}<a parentName="p" {...{
        "href": "https://github.com/remarkjs/remark-toc"
      }}>{`remark-toc`}</a>{` to add a generated table of contents based on the other content in the MDX file or `}<a parentName="p" {...{
        "href": "https://github.com/manovotny/remark-mdx-metadata"
      }}>{`remark-mdx-metadata`}</a>{` to externally modify MDX metadata and save the modified content back to the original file.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      